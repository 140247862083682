@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*
* SHARED
*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
* LAYOUT
*/
.introduction {
  background-color: #002c45;
}

.introduction__heading {
  font-size: 1.875rem;
  color: #ffffff;
  font-weight: bold;
}

.introduction__content {
  color: #ffffff;
  padding-top: 22%;
  padding-bottom: 2rem;
  margin-top: 22%;
}

.introduction .blender {
  width: 100%;
  margin: -48% 0 2rem;
  background-color: #002c45;
  color: #ffffff;
}

.section {
  padding-top: 5%;
  padding-bottom: 5%;
  position: relative;
  background-color: white;
}

.section__heading {
  font-size: 1.875rem;
  color: #002c45;
  font-weight: bold;
}

.section__content {
  background-color: #ffffff;
  padding: 5%;
  border-style: solid;
  border-width: 8px;
}

@media screen and (min-width: 800px) {
  .section__content {
    border-width: 13px;
  }
}

.section.-dark {
  background: white linear-gradient(to bottom, transparent 0%, transparent 50%, #002c45 50%);
}

.section.-dark .section__content {
  border-color: #002c45;
}

.section.-light.-right-text::before {
  content: "";
  position: absolute;
  background: linear-gradient(to bottom, transparent 0%, transparent 15%, #f1f5f7 15%, #f1f5f7 85%, transparent 85%);
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  z-index: -1;
}

.section.-light.-left-text {
  background: linear-gradient(to bottom, transparent 0%, transparent 50%, #f1f5f7 50%);
}

.section.-light .section__content {
  border-color: #f1f5f7;
}

.section.-alt::before {
  content: "";
  position: absolute;
  background: linear-gradient(to bottom, transparent 0%, transparent 25%, #ffd700 25%, #ffd700 75%, transparent 75%);
  top: 0;
  bottom: 0;
  right: 50%;
  left: 0;
  z-index: -1;
}

.section.-alt.-left-text::before {
  left: 50%;
  right: 0;
}

.section.-alt .section__content {
  border-color: #ffd700;
}

.section.-left-text .centered, .section.-right-text .centered {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .section.-left-text .centered, .section.-right-text .centered {
    flex-direction: row;
  }
}

.section__content {
  z-index: 3;
}

.section__image {
  display: none;
}

@media screen and (min-width: 600px) {
  .section__image {
    display: block;
  }
  .section__image, .section__content {
    flex-basis: 50%;
    max-width: 55%;
  }
}

@media screen and (min-width: 600px) {
  .section.-left-text .section__content {
    margin-right: -2%;
  }
  .section.-left-text .section__image {
    margin-left: -2%;
  }
}

@media screen and (min-width: 600px) {
  .section.-right-text .centered {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 600px) {
  .section.-right-text .section__content {
    margin-left: -2%;
  }
  .section.-right-text .section__image {
    margin-right: -2%;
  }
}

.blender {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 50%;
  background: #303030 url(/dist/images/introduction.jpg) no-repeat 50% 50%/cover;
}

.blender__buttons {
  position: absolute;
  z-index: 3;
  right: 1em;
  bottom: 1em;
}

.blender__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent none;
  box-shadow: none;
}

.blender__button:focus {
  box-shadow: none;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item.-animating {
  animation: blend-in 1800ms cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: forwards;
}

.blender.-paused .blender__item {
  animation-play-state: paused;
}

.blender__title, .blender__description {
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 51;
}

main,
.header  {
  background-color: #ffd700;
}
