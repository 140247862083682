.introduction {
   background-color: $color-brand;

   &__heading {
      font-size: rem(30px);
      color: #ffffff;
      font-weight: bold;
   }

   &__content {
      color: #ffffff;
      padding-top: 22%;
      padding-bottom: 2rem;
      margin-top: 22%;
   }

   .blender {
      width: 100%;
      margin: -48% 0 2rem;
      background-color: $color-brand;
      color: #ffffff;
   }
}

.section {
   padding-top: 5%;
   padding-bottom: 5%;
   position: relative;
   background-color: white;

   &__heading {
      font-size: rem(30px);
      color: $color-brand;
      font-weight: bold;
   }

   &__content {
      background-color: #ffffff;
      padding: 5%;
      border: {
         style: solid;
         width: 8px;
      }

      @include min(800px) {
         border-width: 13px;
      }
   }

   &.-dark {
      background: white linear-gradient(to bottom, transparent 0%, transparent 50%, $color-brand 50%);

      .section__content {
         border-color: $color-brand;
      }
   }

   &.-light {
      &.-right-text {
         &::before {
            content: "";
            position: absolute;
            background: linear-gradient(
               to bottom,
               transparent 0%,
               transparent 15%,
               $color-tertiary 15%,
               $color-tertiary 85%,
               transparent 85%
            );
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
            z-index: -1;
         }
      }

      &.-left-text {
         background: linear-gradient(to bottom, transparent 0%, transparent 50%, $color-tertiary 50%);
      }

      .section__content {
         border-color: $color-tertiary;
      }
   }

   &.-alt {
      &::before {
         content: "";
         position: absolute;
         background: linear-gradient(
            to bottom,
            transparent 0%,
            transparent 25%,
            $color-secondary 25%,
            $color-secondary 75%,
            transparent 75%
         );
         top: 0;
         bottom: 0;
         right: 50%;
         left: 0;
         z-index: -1;
      }

      &.-left-text::before {
         left: 50%;
         right: 0;
      }

      .section__content {
         border-color: $color-secondary;
      }
   }

   &.-left-text,
   &.-right-text {
      .centered {
         display: flex;
         justify-content: space-between;
         align-items: center;
         flex-direction: column;

         @include min(600px) {
            flex-direction: row;
         }
      }
   }

   &__content {
      z-index: 3;
   }

   &__image {
      display: none;
   }

   @include min(600px) {
      &__image {
         display: block;
      }

      &__image,
      &__content {
         flex-basis: 50%;
         max-width: 55%;
         //flex-grow: 1;
      }
   }

   &.-left-text {
      @include min(600px) {
         .section__content {
            margin-right: -2%;
         }

         .section__image {
            margin-left: -2%;
         }
      }
   }

   &.-right-text {
      .centered {
         @include min(600px) {
            flex-direction: row-reverse;
         }
      }

      @include min(600px) {
         .section__content {
            margin-left: -2%;
         }

         .section__image {
            margin-right: -2%;
         }
      }
   }
}
