/*
* BASE DEPENDENCIES
*/

@import "libraries/ecco/ecco";

/*
* SHARED
*/

@import "shared/vars";

/*
* LAYOUT
*/

@import "layout/homepage", "blocks/blender";

main,
.header  {
   background-color: $color-secondary;
}
