.blender {
   position: relative;
   z-index: 2;
   overflow: hidden;
   padding-bottom: 50%;
   background: #303030 url(/dist/images/introduction.jpg) no-repeat 50% 50% / cover;

   &__buttons {
      position: absolute;
      z-index: 3;
      right: 1em;
      bottom: 1em;
   }

   &__button {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent none;
      box-shadow: none;

      &:focus {
         box-shadow: none;
      }
   }

   @keyframes blend-in {
      from {
         display: block;
         opacity: 0;
      }
      to {
         display: block;
         opacity: 1;
      }
   }

   &__item {
      @include fill;
      background: {
         position: 50% 50%;
         repeat: no-repeat;
         size: cover;
      }

      &.-animating {
         animation: blend-in 1800ms cubic-bezier(0.075, 0.82, 0.165, 1);
         animation-fill-mode: forwards;
      }
   }

   &.-paused .blender__item {
      animation-play-state: paused;
   }

   &__title,
   &__description {
      position: absolute;
      right: 1em;
      bottom: 1em;
      z-index: 51;
   }
}
